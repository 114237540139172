import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import DownArrow from '../../assets/img/downArrow.svg';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  placeSelf: 'center',
  boxShadow: 'none',
  margin: '0 0 0.313rem 0',
  background: theme.palette.background.blackGrey,
  border: `1px solid ${theme.customColors.wrapperGrey}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.MuiAccordion-root.Mui-expanded': {
    margin: '0 0 0.313rem 0',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'isAlert',
})(({ theme, isAlert }) => ({
  minHeight: '3.5rem !important',
  backgroundColor: theme.palette.background.darkishBlackBlue,
  borderBottom: `1px solid ${alpha(theme.customColors.black, 0.125)}`,
  paddingLeft: '1.25rem',
  paddingRight: '1.5rem',
  borderRadius: '0.25rem',
  height: isAlert ? '' : '3.5rem',
  '&$expanded': {
    minHeight: '3.5rem',
  },
  '.MuiAccordionSummary-content': {
    alignItems: 'center',
    marginRight: '1rem',
    marginTop: '1.156rem !important',
    marginBottom: '1.156rem !important',
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: '1.156rem 1rem 1.156rem 0 !important',
  },
  '&.Mui-disabled': {
    color: theme.palette.text.darkGrey,
    opacity: 1,
    '.MuiAccordionSummary-content': {
      filter:
        'brightness(0) saturate(100%) invert(35%) sepia(3%) saturate(630%) hue-rotate(175deg) brightness(96%) contrast(98%)',
    },
    '.MuiAccordionSummary-expandIconWrapper': {
      filter:
        'brightness(0) saturate(100%) invert(35%) sepia(3%) saturate(630%) hue-rotate(175deg) brightness(96%) contrast(98%)',
    },
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.background.darkishBlue,
  padding: '0',
  overflowX: 'hidden',
  overflowY: 'auto',
}));

function AccordionSummaryFun({ expandIcon = null, isAlert = false, ...props }) {
  return (
    <StyledAccordionSummary
      expandIcon={expandIcon || <DownArrow height="10" width="16" />}
      isAlert={isAlert}
      {...props}
    />
  );
}

AccordionSummaryFun.propTypes = {
  expandIcon: PropTypes.node,
  isAlert: PropTypes.bool,
};

function AccordionDetailsFun({ children, ...other }) {
  return <StyledAccordionDetails {...other}>{children}</StyledAccordionDetails>;
}

AccordionDetailsFun.propTypes = {
  children: PropTypes.node,
};

function CustomizedAccordions(props) {
  const { children, ...other } = props;
  return <StyledAccordion {...other}>{children}</StyledAccordion>;
}
CustomizedAccordions.propTypes = {
  children: PropTypes.node.isRequired,
};
CustomizedAccordions.AccordionSummary = AccordionSummaryFun;
CustomizedAccordions.AccordionDetails = AccordionDetailsFun;
export default CustomizedAccordions;
