import { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { globalstate$ } from '@smf/ui-util-app';
import { IconButton } from '@mui/material';
import Accordion from '../../components/SMFAccordion';
import Button from '../../components/Button';
import * as S from './style';
import SMFSwitch from '../../components/SMFSwitch';
import InfoIcon from '../../assets/img/InfoTooltipIcon.svg';
import {
  ACCORDIAN_LABELS,
  ACCORDIAN_TITLES,
  BUTTON_TEXT,
  SENSOR_BASED_PDM_STATUS_KEY,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import { useToastContext } from '../../context/toastContext';
import MESSAGE_STRINGS from '../../constants/en-us';
import { updatePMConfig } from '../../utils/apiHelpers';
import { updateRxjsState, useRxjsState } from '../../hooks/useRxjsState';

export const onSaveClick = (
  status,
  sensorBasedPdmStatus,
  sensorBasedPdm,
  plantId,
  updatePMConfigAPI,
) => {
  if (sensorBasedPdmStatus === sensorBasedPdm)
    updatePMConfigAPI({ factoryId: plantId, status, module: 'pm' });
  else
    updatePMConfigAPI({
      factoryId: plantId,
      status,
      sensorBasedPdmStatus,
      module: 'pm',
    });
};

export default function UISettings() {
  const { AccordionDetails, AccordionSummary } = Accordion;
  const { toastDispatch } = useToastContext();
  const { rxjsState } = useRxjsState();
  const [status, setStatus] = useState(
    globalstate$.value?.configStatus?.pm ?? false,
  );
  const [sensorBasedPdmStatus, setSensorBasedPdmStatus] = useState(
    globalstate$.value?.configStatus?.sensorBasedPdm ?? false,
  );
  const [disableSave, setDisableSave] = useState(false);
  const { plantId } = rxjsState;

  // Update Status After common status api call
  useEffect(() => {
    setStatus(globalstate$.value?.configStatus?.pm ?? false);
    setSensorBasedPdmStatus(
      globalstate$.value?.configStatus?.sensorBasedPdm ?? false,
    );
  }, [rxjsState?.configStatus?.pm, rxjsState?.configStatus?.sensorBasedPdm]);

  // Disable Save Logic
  useEffect(() => {
    setDisableSave(
      rxjsState?.configStatus?.pm === status &&
        rxjsState?.configStatus?.sensorBasedPdm === sensorBasedPdmStatus,
    );
  }, [
    rxjsState?.configStatus?.pm,
    rxjsState?.configStatus?.sensorBasedPdm,
    sensorBasedPdmStatus,
    status,
  ]);

  // Update API Calling
  const { mutate: updatePMConfigAPI, isLoading: updatePMConfigLoading } =
    useMutation(
      ['update-pm-config'],
      async (payload) => {
        const result = await updatePMConfig(payload);
        return result;
      },
      {
        onSuccess: (result) => {
          // update pm status in Rxjs State
          if (SENSOR_BASED_PDM_STATUS_KEY in result) {
            updateRxjsState({
              configStatus: {
                ...rxjsState?.configStatus,
                pm: result.status,
                sensorBasedPdm: result.sensorBasedPdmStatus,
              },
            });
          } else {
            updateRxjsState({
              configStatus: {
                ...rxjsState?.configStatus,
                pm: result.status,
              },
            });
          }
          // Success Toaster
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
            payload: {
              message: MESSAGE_STRINGS.TOAST_SUCCESS_MESSAGE,
            },
          });
        },
        onError: (err) => {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: {
              message: MESSAGE_STRINGS.TOAST_ERROR_MESSAGE,
            },
          });
          console.error('Error While Updating PM Config', err);
        },
        enabled: !!plantId,
      },
    );

  return (
    <Accordion defaultExpanded>
      <AccordionSummary>{ACCORDIAN_TITLES.UI_SETTINGS}</AccordionSummary>
      <AccordionDetails>
        <S.ConfigRow>
          <S.OuterFlexContainer>
            <S.InnerFlexContainer>
              <S.InfoIconWrapper>
                <S.StyledTooltip
                  title={MESSAGE_STRINGS.PM_ENABLE_TOOLTIP}
                  data-testid="pm-config-tooltip-l4e"
                  placement="bottom-start"
                >
                  <IconButton sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <InfoIcon
                      height="1.5rem"
                      width="1.5rem"
                      data-testid="pm-config-enable-toolip-icon-l4e"
                    />
                  </IconButton>
                </S.StyledTooltip>
              </S.InfoIconWrapper>
              <S.Description>{ACCORDIAN_LABELS.ENABLE_PM_L4E}</S.Description>
              <S.SwitchContainer>
                <SMFSwitch
                  checked={status}
                  onChange={() => {
                    setStatus((previousState) => !previousState);
                  }}
                />
              </S.SwitchContainer>
            </S.InnerFlexContainer>
          </S.OuterFlexContainer>
        </S.ConfigRow>
        <S.ConfigRow>
          <S.OuterFlexContainer>
            <S.InnerFlexContainer>
              <S.InfoIconWrapper>
                <S.StyledTooltip
                  title={MESSAGE_STRINGS.PM_ENABLE_TOOLTIP}
                  data-testid="spc-config-tooltip-cai"
                  placement="bottom-start"
                >
                  <IconButton sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <InfoIcon
                      height="1.5rem"
                      width="1.5rem"
                      data-testid="pm-config-enable-toolip-icon-cai"
                    />
                  </IconButton>
                </S.StyledTooltip>
              </S.InfoIconWrapper>
              <S.Description>{ACCORDIAN_LABELS.ENABLE_PM_CAI}</S.Description>
              <S.SwitchContainer>
                <SMFSwitch
                  data-testid="sensor-based-pdm-toggle"
                  checked={sensorBasedPdmStatus}
                  onChange={() => {
                    setSensorBasedPdmStatus((previousState) => !previousState);
                  }}
                />
              </S.SwitchContainer>
            </S.InnerFlexContainer>
          </S.OuterFlexContainer>
        </S.ConfigRow>
        <S.SaveButtonContainer data-testid="saveButton">
          <Button
            onClick={() =>
              onSaveClick(
                status,
                sensorBasedPdmStatus,
                rxjsState?.configStatus?.sensorBasedPdm,
                plantId,
                updatePMConfigAPI,
              )
            }
            disabled={updatePMConfigLoading || disableSave}
          >
            {BUTTON_TEXT.SAVE}
          </Button>
        </S.SaveButtonContainer>
      </AccordionDetails>
    </Accordion>
  );
}
