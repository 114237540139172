import { Tooltip, styled, tooltipClasses } from '@mui/material';

export const ConfigRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isErrorState',
})(({ theme, isErrorState }) => ({
  fontSize: '0.875rem',
  padding: '0 1.25rem',
  height: isErrorState ? '6rem' : '3rem',
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.customColors.wrapperGrey}`,
  borderBottom: `1px solid ${theme.customColors.wrapperGrey}`,
}));

export const Description = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const SaveButtonContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '3.5rem',
  padding: '0.5rem',
}));

export const OuterFlexContainer = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}));

export const InnerFlexContainer = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
}));

export const SwitchContainer = styled('div')(() => ({
  padding: '1rem',
}));

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.customColors.eerieBlack,
    border: `0.06rem solid ${theme.customColors.metalGrey}`,
    boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.5)',
    borderRadius: '0.25rem',
    padding: '.625rem 0 .625rem .625rem',
    fontColor: theme.customColors.lightYellowishGray,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    maxWidth: '14.375rem',
  },
}));

export const InfoIconWrapper = styled('div')(() => ({
  marginRight: '1rem',
}));
